import React from "react"

import Layout from "../v0/layouts/Layout"
import SEO from "../v0/seo/seo"

const SlackBot = () => (
  <Layout>
    <SEO title="Slackbot" />
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h2 style={{ margin: "auto auto 50px auto" }}>
        Install the DevWell Slackbot
      </h2>
      <a
        style={{ margin: "auto" }}
        href="https://slack.com/oauth/v2/authorize?client_id=910772145603.1285633326341&redirect_uri=https://devwell.org/slackbotsuccess&scope=app_mentions:read,chat:write,chat:write.public,groups:history,im:history,im:write,incoming-webhook,mpim:history,team:read,commands&user_scope="
      >
        <img
          alt="Add to Slack"
          height="80"
          width="278"
          src="https://platform.slack-edge.com/img/add_to_slack.png"
          srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
        />
      </a>
    </div>
  </Layout>
)

export default SlackBot
